<template>
    <div class="flex-item">


      <div class="header">
        <h1 style="text-align: center;">Вебстудия <span>ЕВС</span></h1>
      </div>

      <div class="tagline">
        <p style="text-align: center;" class="corn"><strong>Простые решения для сложных проблем</strong></p>
        <p style="text-align: center; margin-top: 15px;">Мощно, качественно, надёжно</p>
      </div>

      <div>
        <a class="button" href="mailto:info@evs-studio.ru">Связаться с нами</a>
      </div>
    </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import "styles/main.scss";

* {
  margin: 0;
  padding: 0;
}

.corn {
  color: $corn;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $oldlace;
  background-image: url("~@/assets/content-bg01.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  .flex-item {
    width: 768px;

    &:nth-child(1) {
      order: 0;
      flex: 0 1 768px;
      align-self: baseline;
      margin-top: -128px;
    }
  }

  .button {
    font-weight: 700;
    font-style: normal;
    color: $oldlace;
    background-color: $corn;
    border-color: transparent;
    padding: 15px 10px;
    min-width: 203px;
    font-size: 15px;
    position: relative;
    text-align: center;
    z-index: 1;
    transition: .3s;
    line-height: 19px;
    border-width: 3px;
    border-style: solid;
    border-radius: 40px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      outline: 0;
      background-color: $enoby-clay;
      border-color: transparent;
    }
  }

  .header {
    padding-bottom: 15px;

    h1 {
      transition: .3s;
      font-weight: 700;
      font-style: normal;
      color: $oldlace;
      font-size: 70px;
      line-height: 1.1;
      letter-spacing: 0;
      margin: 0;

      span {
        color: $corn;
      }
    }
  }

  .tagline {
    padding-bottom: 45px;

    p {
      transition: .3s;
      font-weight: 300;
      font-style: normal;
      font-size: 24px;
      line-height: 1.5;
      letter-spacing: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }
}
</style>
